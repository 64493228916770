import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const cards = [
  {
    id: 1,
    title: "Ajay Kumar Dwivedi	",
    description: "President",
    image: "/images/committee/AjayDwivedi.jpg",
  },
  {
    id: 2,
    title: "	S.Viswanathan",
    description: "	Vice President",
    image: "/images/committee/SViswanathan.jpg",
  },
  {
    id: 3,
    title: "Gita Singh",
    description: "Secretary",
    image: "/images/committee/GitaSingh.jpg",
  },
  {
    id: 4,
    title: "Anil Rajput",
    description: "Treasurer",
    image: "/images/committee/AnilRajput.jpg",
  },
  {
    id: 5,
    title: "Madhulika Burman",
    description: "Trustee",
    image: "/images/committee/MadhulikaBurman.jpg",
  },

  {
    id: 6,
    title: "Indra Tripathi",
    description: "Trustee",
    image: "/images/committee/IndraTripati.jpg",
  },

  {
    id: 7,
    title: "V.B.Sharma",
    description: "Trustee",
    image: "/images/committee/VBSharma.jpg",
  },

  {
    id: 8,
    title: "H.Madhavan",
    description: "Trustee",
    image: "/images/committee/HMadhavan.jpg",
  },
  {
    id: 9,
    title: "Krishan Pal Singh",
    description: "Trustee",
    image: "/images/committee/KPSingh.jpg",
  },
  {
    id: 10,
    title: "Ambar Chatterjee",
    description: "Trustee",
    image: "/images/committee/AmbarChatterjee.jpg",
  },
];

export default function Committee() {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: { xs: "center", md: "space-evenly" },
          alignItems: "center",
        }}
      >
        {cards.map((card) => (
          <Grid
            item
            key={card.id}
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card sx={{ width: 300, boxShadow: 5 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="250"
                  image={card.image}
                  alt={card.title}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {card.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {card.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
