import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
} from "@mui/material";

const Join = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS send function
    emailjs
      .send(
        "service_omv8wkf", // Replace with your Service ID
        "template_gvim8lc", // Replace with your Template ID
        {
          user_name: formData.name,
          user_email: formData.email,
          user_message: formData.message,
        },
        "BEwFDm4Wt4pZd490J" // Replace with your Public Key
      )
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log("Error in sending email:", error.text);
          alert("Failed to send message. Please try again later.");
        }
      );
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Paper elevation={3} sx={{ padding: 4, bgcolor: "#f2ede4" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" mb={3}>
          We'd love to hear from you! Please fill out the form below.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Name Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Your Name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            {/* Email Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Your Email"
                variant="outlined"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            {/* Message Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="message"
                label="Your Message"
                variant="outlined"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ padding: 1.5 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Join;
