import { Box, Grid2, Stack, Typography, Divider } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Committee from "./Committee";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Parkinsans", // Replace with your font
  },
});

function About() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid2
          container
          sx={{
            bgcolor: "#61dafb",
            height: "30vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            color="white"
            align="center"
            sx={{
              fontSize: {
                xs: "1.2rem",
                sm: "1.5rem",
                md: "2rem",
                lg: "3.5rem",
              }, // Responsive font size
            }}
          >
            Of the seniors, for the seniors, by the seniors
          </Typography>
        </Grid2>

        <Grid2
          container
          justifyContent={"center"}
          // alignItems={"center"}
          // height={"40vh"}
          bgcolor={"#b9e6b3"}
        >
          <Box display={"flex"} width={"80%"} margin={2}>
            <Typography variant="h4" align="center" color="#076313">
              <br />
              Vayavalamban was conceptualised in 2023 as a not-for-profit
              organisation in Vadodara (regd no. Gujarat/3904/Vadodara) to
              connect senior citizens who are living on their own after having
              lived a purposeful life for decades. <br />
              <br /> As joint families are being replaced by nuclear families
              and children are moving on for better opportunities, parents are
              left behind to live alone in their twilight years. This social
              trend is a serious issue where senior citizens are having to cope
              with loneliness that results in poor mental and physical health.{" "}
              <br />
              <br />
              Vayavalamban is a community of senior citizens who firmly believe
              in living their golden years independently, with dignity and
              purpose. A wide range of activities are organised to keep the
              members connected and remain physically and emotionally healthy.
              <br />
              <br />
              Utilising the talent and experience of its members, Vayavalamban
              has also taken a pledge to serve humanity and help build a society
              with right values.
              <br />
            </Typography>
          </Box>
          {/* </Grid2> */}

          <Grid2
            container
            justifyContent={"center"}
            alignItems={"center"}
            borderTop={1}
            borderColor={"#c3c7c3"}
          >
            <Box
              display={"flex"}
              width={"80%"}
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={5}
              marginBottom={5}
            >
              <Stack
                direction={"column"}
                alignContent={"center"}
                alignItems={"center"}
              >
                {" "}
                <Typography variant="h2"> The Team</Typography>
                <KeyboardDoubleArrowDownIcon fontSize="large" />
              </Stack>
            </Box>
            <Committee />
          </Grid2>
        </Grid2>
      </ThemeProvider>
    </div>
  );
}
export default About;
