import { Box, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState, useEffect } from "react";

function Home() {
  return (
    <Grid
      container
      sx={{
        height: "90vh", // Full viewport height
        width: "100%",
        position: "relative",
        backgroundImage: `url("/images/home2.jpg")`, // Replace with your image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // filter: "blur(2px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backgroundBlendMode: "overlay",
      }}
    >
      {/* Left Section */}
      <Grid
        item
        xs={12}
        md={6} // Takes half of the width on medium screens and above
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <Typography variant="h1" align="center" fontFamily={"Parkinsans"}>
          <span style={{ color: "#AAFF00" }}>Greening</span> the{" "}
          <span style={{ color: "#D3D3D3" }}>greying</span> years
        </Typography>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={6} // Takes half of the width on medium screens and above
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          color: "white",
          //   bgcolor: "lightcoral", // Background color for visibility
        }}
      ></Grid>
    </Grid>
  );
}

export default Home;
